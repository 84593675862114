import(/* webpackMode: "eager", webpackExports: ["ViewTransitions"] */ "/home/runner/work/platform/platform/frontend/node_modules/.pnpm/next-view-transitions@0.3.2_next@15.0.0_@opentelemetry+api@1.9.0_@playwright+test@1.48.0_reac_ohshcizrkqodv6nfypkkamtuza/node_modules/next-view-transitions/dist/index.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/platform/platform/frontend/node_modules/.pnpm/next@15.0.0_@babel+core@7.25.2_@opentelemetry+api@1.9.0_@playwright+test@1.48.0_react-dom@19._hinnoins2zvisso6ysalswbiru/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/home/runner/work/platform/platform/frontend/node_modules/.pnpm/driver.js@1.3.1/node_modules/driver.js/dist/driver.css");
;
import(/* webpackMode: "eager" */ "/home/runner/work/platform/platform/frontend/node_modules/.pnpm/filepond@4.31.4/node_modules/filepond/dist/filepond.min.css");
;
import(/* webpackMode: "eager", webpackExports: ["RootLayout"] */ "/home/runner/work/platform/platform/frontend/packages/component/src/root-layout/index.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/platform/platform/frontend/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["ProjectDefender"] */ "/home/runner/work/platform/platform/frontend/src/components/section/project-defender.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AuthProvider","AuthReady"] */ "/home/runner/work/platform/platform/frontend/src/hooks/auth.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PermissionProvider"] */ "/home/runner/work/platform/platform/frontend/src/hooks/permissions.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ContactSection"] */ "/home/runner/work/platform/platform/frontend/src/layouts/base-layout/navigation/contact-section.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LegacyInformationSection"] */ "/home/runner/work/platform/platform/frontend/src/layouts/base-layout/navigation/legacy/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["BaseLayoutProvider"] */ "/home/runner/work/platform/platform/frontend/src/layouts/base-layout/shared.tsx");
